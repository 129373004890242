import type {ReactElement} from 'react';
import React, {isValidElement, useCallback, useEffect, useState} from 'react';
import {Dropdown} from '@Components/dropdown-v2';
import {ALIGNMENT_TYPE, DROPDOWN_POSITION} from '@Components/dropdown-v2/dropdown.types';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {isVideoFile} from '@Utils/video.util';
import {Button, Size, Type} from '@Components/button';
import {SkeletonBrick} from '@Components/skeleton-brick';
import {Pill, PILL_CONTENT_TYPE, PILL_SIZE} from '@Components/pill';
import styles from './intro-popup.module.scss';

export interface IntroPopupProps {
  userHasSeenCallbackOnClose: VoidFunction;
  assetUrl: string;
  content?: string | ReactElement;
  title?: string;
  arrowOffset?: number;
  primaryFooterAction?: ReactElement<typeof Button>;
  hideSecondaryAction?: boolean;
  secondaryActionText?: string;
  mediaWidth?: number;
  leftOffset?: number;
  dropdownAlignment?: ALIGNMENT_TYPE;
  shouldFlip?: boolean;
  shouldShift?: boolean;
  arrowClasses?: string;
  assetClasses?: string;
  popupContentClasses?: string;
  elementId?: string;
  isMobile?: boolean;
  hasSmoothFadeIn?: boolean;
  showNewFeaturePill?: boolean;
}

const DEFAULT_MEDIA_WIDTH_FOR_INTRO_POPUP = 193; // px

export function IntroPopup({
  hideSecondaryAction = false,
  mediaWidth = DEFAULT_MEDIA_WIDTH_FOR_INTRO_POPUP,
  secondaryActionText = window.i18next.t('pmwjs_later'),
  dropdownAlignment = ALIGNMENT_TYPE.BOTTOM,
  shouldFlip = true,
  shouldShift = true,
  arrowClasses = '',
  assetClasses = '',
  popupContentClasses,
  elementId,
  isMobile = false,
  showNewFeaturePill = false,
  ...props
}: IntroPopupProps): ReactElement | null {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);

  useEffect(() => {
    window.PMW.initTracking();
  }, []);

  const onClosePopup = (): void => {
    props.userHasSeenCallbackOnClose();
  };

  const onMediaLoaded = (): void => {
    setIsMediaLoaded(true);
  };

  const getMedia = (): ReactElement | null => {
    if (!props.assetUrl) {
      return null;
    }

    if (isVideoFile(props.assetUrl)) {
      return (
        <div className={`${isMediaLoaded ? '' : '_hidden'} flex-center _fit-width spacing-p-b-4 spacing-p-l-3 spacing-p-r-3 ${styles.mediaContainer}`}>
          <video autoPlay muted loop crossOrigin="anonymous" width={mediaWidth}>
            <source type="video/mp4" src={props.assetUrl} onLoad={onMediaLoaded} />
          </video>
        </div>
      );
    }

    return (
      <div className={`${isMediaLoaded ? '' : '_hidden'} flex-center _fit-width spacing-p-b-4 spacing-p-b-2 spacing-p-l-3 spacing-p-r-3 ${styles.mediaContainer}`}>
        <img className={`_full-height ${assetClasses}`} src={props.assetUrl} alt="media onboarding" width={mediaWidth} onLoad={onMediaLoaded} />
      </div>
    );
  };

  const getMediaSkeleton = (): ReactElement | null => {
    if (isMediaLoaded) {
      return null;
    }

    return (
      <div className={`flex-center _fit-width spacing-p-b-4 spacing-p-l-2 spacing-p-r-2 ${styles.mediaContainer}`}>
        <SkeletonBrick width={mediaWidth - 21} height={90} />
      </div>
    );
  };

  const getContent = (): ReactElement | null => {
    if (!props.content) {
      return null;
    }

    if (isValidElement(props.content)) {
      return props.content;
    }

    return (
      <div className="flexbox spacing-p-b-3 spacing-p-l-3 spacing-p-r-3">
        <Text val={props.content as string} size={TextSize.XSMALL} />
      </div>
    );
  };

  const getTitle = (): ReactElement => {
    if (!props.title) {
      return <div />;
    }

    if (showNewFeaturePill) {
      return (
        <div className="flexbox spacing-p-t-1 align-items-center">
          <Text val={props.title ?? ''} size={TextSize.SMALL} bold className={`${styles.title} content-body`} />
          <Pill
            className="spacing-m-l-2"
            size={PILL_SIZE.SMALL}
            content={{
              type: PILL_CONTENT_TYPE.TEXT,
              text: window.i18next.t('pmwjs_new'),
            }}
          />
        </div>
      );
    }

    return <Text val={props.title ?? ''} size={TextSize.SMALL} bold className='content-body spacing-p-t-1'/>;
  };

  const getHeader = (): ReactElement => {
    return (
      <div className="flex-justify-between spacing-p-b-3 spacing-p-l-3 spacing-p-r-1 spacing-p-t-2 align-items-start">
        {getTitle()}
        <Icon onClick={onClosePopup} icon="icon-close" shape={IconShape.SQUARE} type={IconType.GHOST} size={IconSize.SIZE_ICON_16} />
      </div>
    );
  };

  const getActionItems = (): ReactElement | null => {
    if (props.primaryFooterAction) {
      return (
        <div className={`${styles.popupFooter} flexbox align-items-center justify-content-end`}>
          {hideSecondaryAction ? null : <Button onClick={onClosePopup} text={secondaryActionText} type={Type.SECONDARY} size={Size.XSMALL} />}
          {props.primaryFooterAction ?? null}
        </div>
      );
    }

    return null;
  };

  const getPopupContent = useCallback((): ReactElement => {
    return (
      <div id="pmw-intro-popup-content-container">
        {getHeader()}
        {getMediaSkeleton()}
        {getMedia()}
        {getContent()}
        {getActionItems()}
      </div>
    );
  }, [isMediaLoaded]);

  return (
    <div className={`${styles.introPopupContainer} ${props.hasSmoothFadeIn ? styles.fadeIn : ''}`}>
      <Dropdown
        open
        id={`${elementId === undefined ? 'pmw-intro-popup' : elementId}`}
        key="pmw-intro-popup-key"
        alignment={dropdownAlignment}
        selector={<div className="intro-anchor" />}
        popup={getPopupContent()}
        useDefaultArrow
        shouldFlip={shouldFlip}
        shouldShift={shouldShift}
        arrowClasses={`${styles.arrow} ${arrowClasses}`}
        popUpHasCustomWidth
        popUpHasCustomHeight
        popupClassName={`${styles.popupContent} ${popupContentClasses}`}
        popupSpacingClass="spacing-p-0"
        leftOffset={props.leftOffset}
        className={`${styles.dropdown} ${styles.zIndex900}`}
        onClose={onClosePopup}
        doesCloseOnOuterClick={false}
        position={DROPDOWN_POSITION.ABSOLUTE}
        arrowOffset={props.arrowOffset}
      />
    </div>
  );
}
