import {UploadsMediaType} from '@Panels/user-media-panel/user-media-panel.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {ImageData, VideoData} from '@Libraries/add-media-library';
import {openUserMediaModal} from '@Modals/user-media-modal';
import {isUserLoggedIn} from '@Libraries/user.library';
import {openErrorModal} from '@Modals/error-modal';
import type {CreateDesignFromUploadOpts} from '@Components/design-selector/design-selector.types';
import {openAuthenticateModal} from '@Modals/authenticate-modal';
import type {IntroPopupProps} from '@Components/intro-popup';
import {IntroPopup} from '@Components/intro-popup';
import {renderElement} from '@Utils/root-element.util';
import {Provider} from 'react-redux';
import type {ReactElement} from 'react';
import React from 'react';
import {ALIGNMENT_TYPE} from '@Components/dropdown-v2/dropdown.types';
import type {PillProps} from '@Components/pill';
import {Pill, PILL_BACKGROUND_COLOR, PILL_CONTENT_TYPE, PILL_SIZE} from '@Components/pill';
import {Text, TextSize} from '@Components/text';
import {hasUserSeenUploadMediaPopup, markUserHasSeenUploadMediaPopup} from '@Libraries/poster-library';
import {getAssetUrl} from '@Utils/s3.util';
import {isTabletScreenWidth} from '@Utils/dom.util';

window.PMW.mergePMW(PMW, {
  triggerCreateDesignFromUserUpload(opts: CreateDesignFromUploadOpts) {
    const createPosters = async (items: Array<ImageData | VideoData>, currentIndex?: number, totalPosterCreationsCount?: number): Promise<void> => {
      const posterCreationPromises: Array<Promise<any>> = [];

      showLoading(`createDesignFromUpload`, {text: window.i18next.t('pmwjs_creating')});
      for (let index = 0; index < items.length; index++) {
        posterCreationPromises.push(createPoster(items[index], currentIndex ?? index + 1, totalPosterCreationsCount ?? items.length));
      }

      await Promise.all(posterCreationPromises);

      hideLoading(`createDesignFromUpload`);

      if (currentIndex === totalPosterCreationsCount) {
        if (opts.onAllPostersCreated) {
          opts.onAllPostersCreated(allCreatedPosterIds, failedUploads);
        }
      }
    };

    const createPoster = async (item: ImageData | VideoData, currentIndex?: number, totalPosterCreationsCount?: number): Promise<void> => {
      try {
        const newPosterHashedId = (await window.PMW.writeLocal('poster/createPosterForMediaItem', {
          type: item.type,
          hashedFilename: item.hashedFilename,
          fid: opts.idFolder,
        })) as string;

        opts.onPosterCreated({
          newlyCreatedPosterHashedId: newPosterHashedId,
          posterCreatedFromMediaType: item.type,
          currentIndex,
          totalPosterCreationsCount,
        });
        allCreatedPosterIds.push(newPosterHashedId);
      } catch (error) {
        console.error(error);
        openErrorModal();
      }
    };

    const onAddSelected = async (items: Array<ImageData | VideoData>, currentUploadIndex?: number, totalUploadsCount?: number): Promise<void> => {
      if (!isUserLoggedIn()) {
        openAuthenticateModal({
          fbPermissions: '',
          signupSource: 'default',
          onLoginSuccess: async () => {
            await createPosters(items, currentUploadIndex, totalUploadsCount);
          },
        });
        return;
      }
      await createPosters(items, currentUploadIndex, totalUploadsCount);
    };

    const onErrorDuringUpload = (file: File, currentUploadIndex?: number, totalItemsToUpload?: number): void => {
      failedUploads.push(file);

      if (opts.onErrorDuringUpload) {
        opts.onErrorDuringUpload(file, currentUploadIndex, totalItemsToUpload);
      }

      if (currentUploadIndex === totalItemsToUpload) {
        if (opts.onAllPostersCreated) {
          opts.onAllPostersCreated(allCreatedPosterIds, failedUploads);
        }
      }
    };

    const allCreatedPosterIds: Array<string> = [];
    const failedUploads: Array<File> = [];

    if (opts.onTriggered) {
      opts.onTriggered();
    }

    openUserMediaModal({
      onAddSelectedCTAText: window.i18next.t('pmwjs_create_design'),
      maxSelectionNumber: opts.maxSelectionNumber,
      onAddSelected,
      defaultTab: UploadsMediaType.IMAGE,
      mediaTypesToShow: [UploadsMediaType.IMAGE, UploadsMediaType.VIDEO],
      uploadAcceptedMimetype: 'image/*,video/*',
      onStartAddSelected: opts.onPosterCreationsStarted,
      onUploadError: onErrorDuringUpload,
      isForDesignUpload: true,
    });
  },

  async openUploadMediaPopup(contentContainer: HTMLElement, leftOffset: number) {
    if (!contentContainer) {
      return;
    }

    if (await hasUserSeenUploadMediaPopup()) {
      return;
    }

    const getTextForUploadMediaPopup = (): ReactElement => {
      return (
        <Text
          size={TextSize.XSMALL}
          val={window.i18next.t('pmwjs_user_upload_popup_text')}
          className="flexbox spacing-p-b-3 spacing-p-l-3 spacing-p-r-3 upload-media-popup-content"
        />
      );
    };

    const isTabletWidth = isTabletScreenWidth();

    const onClose = (): void => {
      void markUserHasSeenUploadMediaPopup();
    };

    const props: IntroPopupProps = {
      userHasSeenCallbackOnClose: onClose,
      title: window.i18next.t('pmwjs_upload_your_own_media_popup_title'),
      content: getTextForUploadMediaPopup(),
      assetUrl: getAssetUrl('upload-media-intro-popup-visual.png'),
      mediaWidth: 320,
      leftOffset,
      showNewFeaturePill: true,
      shouldFlip: false,
      isMobile: isTabletWidth,
      dropdownAlignment: ALIGNMENT_TYPE.BOTTOM,
    };

    renderElement(
      contentContainer,
      <Provider store={window.PMW.redux.store}>
        <IntroPopup {...props} />
      </Provider>
    );
  },

  showPillForUploadMediaCTA(contentContainer: HTMLElement) {
    if (!contentContainer) {
      return;
    }

    const pillProps: PillProps = {
      className: '_position-absolute upload-media-pill',
      background: PILL_BACKGROUND_COLOR.SECONDARY_100,
      content: {
        text: window.i18next.t('pmwjs_new'),
        type: PILL_CONTENT_TYPE.TEXT,
        textColor: 'content-body-white',
      },
      size: PILL_SIZE.SMALL,
    };

    renderElement(
      contentContainer,
      <Provider store={window.PMW.redux.store}>
        <Pill {...pillProps} />
      </Provider>
    );
  },
});
