import type { Root} from 'react-dom/client';
import {createRoot} from 'react-dom/client';
import {v4 as uuidv4} from 'uuid';
import type {ReactNode} from 'react';

export const rootElementsHashmap: Record<string, Root> = {};

export const getKeyForElement = (containerElement: HTMLElement): string => {
  if (containerElement.dataset.transformed) {
    return containerElement.dataset.transformed;
  }
  const key = uuidv4();
  containerElement.dataset.transformed = key;
  return key;
};

export const renderElement = (containerElement: HTMLElement, componentToRender: ReactNode) => {
  const key = getKeyForElement(containerElement);

  if (key in rootElementsHashmap) {
    return rootElementsHashmap[key].render(componentToRender);
  }

  const transformedElement = createRoot(containerElement);
  rootElementsHashmap[key] = transformedElement;

  return transformedElement.render(componentToRender);
};
