import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {AuthenticatePanelProps} from '@Panels/authenticate-panel/authenticate-panel.types';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'authenticate-modal-panel';

export type AuthenticateModalProps = AuthenticatePanelProps;

export default function AuthenticateModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.AUTHENTICATE_MODAL];
  });

  const directoryName = 'authenticate-panel';
  const height = 'auto';
  const width = '494px';
  const modalMobileHeight = '700px';

  return (
    <Modal isBottomSheetOnMobile mobileHeight={modalMobileHeight} modalId={MODALS_IDS.AUTHENTICATE_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName={directoryName}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openAuthenticateModal = (props: AuthenticateModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.AUTHENTICATE_MODAL,
      panelProps: props,
    })
  );
};
